import * as React from "react"

function SvgDatabaseStorage(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 64 64"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g data-name="database server">
        <path d="M43.82 20.01A2.986 2.986 0 0041 18h-1v-2h1a3.009 3.009 0 003-3V5a3.009 3.009 0 00-3-3H5a3.009 3.009 0 00-3 3v8a3.009 3.009 0 003 3h1v2H5a3.009 3.009 0 00-3 3v8a3.009 3.009 0 003 3h1v2H5a3.009 3.009 0 00-3 3v8a3.009 3.009 0 003 3h19v8c0 3.94 9.56 6 19 6s19-2.06 19-6V26c0-3.83-9.01-5.87-18.18-5.99zM5 14a1 1 0 01-1-1V5a1 1 0 011-1h36a1 1 0 011 1v8a1 1 0 01-1 1zm33 2v2h-4v-2zm-6 0v2H14v-2zm-20 0v2H8v-2zm12 30H5a1 1 0 01-1-1v-8a1 1 0 011-1h19zM8 34v-2h4v2zm16 0H14v-2h10zm0-8v4H5a1 1 0 01-1-1v-8a1 1 0 011-1h36a.7.7 0 01.14.03C32.34 20.28 24 22.32 24 26zm36 30c0 1.36-6 4-17 4s-17-2.64-17-4v-7.18C29.29 50.91 36.16 52 43 52s13.71-1.09 17-3.18zm0-10c0 1.36-6 4-17 4s-17-2.64-17-4v-7.18C29.29 40.91 36.16 42 43 42s13.71-1.09 17-3.18zm0-10c0 1.36-6 4-17 4s-17-2.64-17-4v-7.18C29.29 30.91 36.16 32 43 32s13.71-1.09 17-3.18zm-17-6c-11 0-17-2.64-17-4s6-4 17-4 17 2.64 17 4-6 4-17 4z" />
        <path d="M42 35h2v2h-2zM42 46h2v2h-2zM42 55h2v2h-2zM11 22H7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zm-1 4H8v-2h2zM19 22h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zm-1 4h-2v-2h2zM11 6H7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V7a1 1 0 00-1-1zm-1 4H8V8h2zM19 6h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V7a1 1 0 00-1-1zm-1 4h-2V8h2zM22 6h14v2H22zM38 6h2v2h-2zM22 10h14v2H22zM38 10h2v2h-2zM11 38H7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zm-1 4H8v-2h2zM19 38h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zm-1 4h-2v-2h2z" />
      </g>
    </svg>
  )
}

export default SvgDatabaseStorage
