import { Button } from "@material-ui/core"
import { Link, navigate } from "gatsby"
import React, { useState } from "react"
import Repairphone from "../../images/repairphone.mp4"
import useGatsbyStripeData from "../hooks/useGatsbyStripeData"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"
import Select from "react-select"
import { brands, modelPhone, problems } from "../../data/mockData"
import Marquee from "../Marquee"

const getModelOptions = (obj, name) =>
  !!name ? obj[name].map(item => ({ value: item, label: item })) : []

const options = name =>
  name.map(item => ({
    value: item,
    label: item,
  }))

const defaultSelectOption = { value: "", label: "Auswählen" }

const customStyles = {
  control: base => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
}

const SelectPhone = () => {
  const [brand, setBrand] = useState(null)
  const [model, setModel] = useState(defaultSelectOption)
  const [problem, setProblem] = useState(defaultSelectOption)
  const [stepper, setStepper] = useState(0)
  const data = useGatsbyStripeData()
  const handleSubmit = e => {
    e.preventDefault()
  }

  return (
    <div className="hero">
      <div className="wrap-hero wrap-select">
        <form className="select-phone" onSubmit={handleSubmit}>
          <h1>Handy Reparatur Berlin</h1>
          <div className="select-phone__quality">
            <li>
              <VerifiedUserIcon /> <span>100 % zuverlässig & fair</span>
            </li>
            <li>
              {" "}
              <VerifiedUserIcon /> <span>100 Keine versteckten Kosten</span>
            </li>
            <li>
              <VerifiedUserIcon /> <span>Express Reparatur in 1er Std.</span>
            </li>
          </div>

          <div className="select-phone__input">
            <div className="select-input brand-phone">
              <h5>Hersteller auswählen</h5>
              <Select
                styles={customStyles}
                placeholder="Apple, Sumsung, etc..."
                options={options(brands)}
                // value={brand}
                value={brand}
                onChange={e => {
                  setBrand(e)
                  setStepper(1)
                  setModel(defaultSelectOption)
                  setProblem(defaultSelectOption)
                }}
                menuColor="red"
              />
            </div>
            <div className="select-input model-phone">
              <h5>Modell auswählen</h5>
              <Select
                styles={customStyles}
                value={model}
                // placeholder={model}
                options={getModelOptions(modelPhone, brand?.value)}
                onChange={e => {
                  setModel(e)
                  setStepper(2)
                }}
                isDisabled={stepper < 1}
              />
            </div>
            <div className="select-input problem-phone">
              <h5>Reparaturservice auswählen</h5>
              <Select
                styles={customStyles}
                value={problem}
                isDisabled={stepper < 2}
                onChange={e => {
                  setProblem(e)
                  setStepper(3)
                }}
                options={options(problems)}
              />
            </div>
          </div>

          <button
            className="btn btn-bg btn-raise btn-action"
            onClick={() => {
              navigate("/shop")
            }}
            disabled={stepper < 3}
          >
            zum Reparaturangebot
          </button>
          {/* <Link className="cta-button" to="/contact">
          </Link> */}
        </form>
      </div>
      <Marquee />
    </div>
  )
}

export default SelectPhone
