import React from "react"

const Marquee = () => {
  return (
    <div className="marquee">
      <div className="marquee-child">
        <span role="img" aria-label="Thay màn hình chất lượng gốc"></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default Marquee
